import React from 'react'
import {css} from 'emotion'

import ReactMapGL, { Marker, StaticMap} from 'react-map-gl';


const styles = {
    footer: css`
        ${tw`relative w-screen bg-grey-light`};
        min-height: 30vh;
        margin-top: 10rem;
    `,
    rightHook: css`
        ${tw`relative z-100 pin-x flex items-center justify-between flex-wrap w-full max-w-row mx-auto bg-white rounded-lg shadow-lg px-4 lg:px-8 py-16`};
        transform: translateY(-8rem);
        background: #FFFFFF;
        box-shadow: 0 28px 54px 0 rgba(140,140,150,0.20);
        border-radius: 8px;
    `,
}

const MapBox=(props)=>(
    <ReactMapGL
        {...props.viewport}
        onViewportChange={(viewport)=>{props.viewportChange(viewport)}}
        mapboxApiAccessToken="pk.eyJ1IjoibWFhemthYmlyIiwiYSI6ImNqczI5NWhobzF2a3UzeXRmMjk0emt1NW8ifQ.a_z-r-y5P5P2qEzRu5ervQ">
        
        <Marker latitude={props.lat} longitude={props.lng} offsetLeft={-20} offsetTop={-10}>
            <img src={require('../images/mapPointer.svg')} alt="Map pointer"/>
        </Marker>

    </ReactMapGL>


);

class Footer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            viewport1: {
                width: '100%',
                height: 400,
                latitude: 37.724080, 
                longitude: -122.455785,
                zoom: 15,
                pitch: 100,
            },
            viewport2: {
                width: '100%',
                height: 400,
                latitude: 37.671446,
                longitude: -122.464355,
                zoom: 15,
                pitch: 100,
            }
        }
    }



    render(){
        return(
            <footer className={styles.footer}>
            <div className={styles.rightHook}>
                <div className={css`${tw`flex items-center flex-wrap w-full lg:w-2/3`}`}>
                    <div>
                        <p className={css`${tw`font-sans font-medium opacity-40 text-grey-darker mb-2`}`}>Available On</p>
                        <div className={css`${tw`flex flex-wrap items-center `}`}>
                            <img className={css`${tw`mr-6`}`} src={require('../images/uberEatsLogo.svg')} alt="Uber Eats Logo"/>
                            <img className={css`${tw`mr-6`}`} src={require('../images/chowNowLogo.svg')} alt="Chow Now Logo"/>
                        </div>
                    </div>
                </div>
                <div className={css`${tw`flex items-center flex-wrap w-full lg:w-1/3`}`}>
                    <div className={css`${tw`relative z-0 overflow-hidden flex w-full lg:mx-4 py-6 text-white font-sans font-bold items-center justify-center text-center bg-black rounded-lg`}`}>
                        <a href="https://ordering.chownow.com/order/13891/locations" 
                            target="_blank"
                            className={css`${tw`flex items-center justify-center text-white absolute h-full w-full pin-t z-10 cursor-pointer no-underline`}`}>
                            Order Delivery
                        </a>
                    </div>
                </div>
            </div>
    
            <div className={css`${tw`flex flex-wrap mx-auto max-w-row items-center justify-between mb-4 px-4 lg:px-6 `}; border-bottom: 0.0625rem solid rgba(50,50,50,0.1)`}>
    
                <div className={css`${tw`w-full lg:w-1/3`}`}>
                    <h4 className={css`${tw`text-3xl font-black`}; letter-spacing: -0.5px;`}>Operating Hours</h4>
                </div>
                <div className={css`${tw`w-full lg:w-1/3`}`}>
                    <ul className={css`${tw`list-reset m-0 lg:text-right font-sans`}`}>
                        <li className={css`${tw`my-4`}`}>Mon - Sat <br/>10:30am - 11:00pm</li>
                        {/* <li className={css`${tw`my-4`}`}>Sundays 11am - 10pm</li> */}
                    </ul>
                </div>
    
            </div>
            <div className={css`${tw`flex flex-wrap justify-between mx-auto max-w-row items-center mb-4 pt-8 pb-16 px-4 lg:px-6 `}; border-bottom: 0.0625rem solid rgba(50,50,50,0.1)`}>
                <div className={css`${tw`w-full`}`}>
                    <h4 className={css`${tw`text-3xl font-black`}; letter-spacing: -0.5px;`}>Locations</h4>
                </div>
                <div className={css`${tw`relative z-0 w-full lg:w-1/3 my-4 lg:my-0`}`}>
                    <MapBox
                        viewport={this.state.viewport1}
                        viewportChange={(viewport)=>{this.setState({viewport1:viewport})}}
                            lat={37.724080}
                            lng={-122.455785}
                    />
                    {/* <div className={css`${tw`absolute z-10 w-2/3 mx-auto pin-x pin-b rounded-full px-8 py-2 text-white`}; background: rgba(50,50,60,0.8);`}>
                        <p className={css`${tw`font-sans text-sm font-medium`}`}>San Francisco</p>
                        <p className={css`${tw`font-sans text-xs mt-1 opacity-70`}`}>1250 Ocean Ave , San Francisco, CA 94112</p>
                    </div> */}
                    <p className={css`${tw`font-sans text-md font-bold tracking-tight mt-8`}`}>San Francisco</p>
                    <p className={css`${tw`font-sans text-xs mt-1 opacity-50`}`}>1250 Ocean Ave , San Francisco, CA 94112</p>
                    <a href="tel:6509941499" className={css`${tw`font-sans text-xs mt-1 opacity-50 no-underline`}`}>(650) 994-1499</a>
                </div>
                <div className={css`${tw`relative z-0 w-full lg:w-1/3 my-4 lg:my-0`}`}>
                    <MapBox
                        viewport={this.state.viewport2}
                        viewportChange={(viewport)=>{this.setState({viewport2:viewport})}}
                        lat={37.671446}
                        lng={-122.464355}
                    /> 
                    {/* <div className={css`${tw`absolute z-10 w-2/3 mx-auto pin-x pin-b rounded-full px-8 py-2 text-white`}; background: rgba(50,50,60,0.8);`}>
                        <p className={css`${tw`font-sans text-sm font-medium`}`}>Colma / Daly City</p>
                        <p className={css`${tw`font-sans text-xs mt-1 opacity-70`}`}>990 B Serramonte Blvd, Colma, CA 94112</p>
                    </div>                */}
                        <p className={css`${tw`font-sans text-md font-bold tracking-tight mt-8`}`}>Colma / Daly City</p>
                        <p className={css`${tw`font-sans text-xs mt-1 opacity-50`}`}>990 B Serramonte Blvd, Colma, CA 94112</p>
                        <a href="tel:6509941499" className={css`${tw`font-sans text-xs mt-1 opacity-50 no-underline`}`}>(650) 994-1499</a>
                </div>
            </div>
    
            <div className={css`${tw`flex flex-wrap items-center justify-between mx-auto max-w-row items-center px-4 lg:px-6 pb-8`}`}>
                <div className={css`${tw`w-full lg:w-1/2`}`}>
                    <img className={css`${tw`m-0`}`} src={require('../images/logo-original.svg')} alt="pokebowls logo"/>
                    <p className={css`${tw`font-sans text-sm text-grey`}`}>Poke Bowl Inc.</p>
                </div>
                <div className={css`${tw`w-full lg:w-1/2 lg:text-right`}`}>
                    <p className={css`${tw`text-xs text-grey-dark font-sans`}`}>
                        Made with love by 
                        <a  className={css`${tw`text-sm text-black block no-underline `}`} href="https://clearcut.design" target="_blank">Clearcut Design Studio</a>
                    </p>
                </div>
            </div>
        </footer>
    );

    }
}

export default Footer;
