import React from 'react'
import {css} from 'emotion';

/* global tw */
const styles = {
    section: css`
      ${tw`relative flex flex-wrap w-screen overflow-hidden z-0 items-center`};
      height: 30rem;
      @media (min-width: 992px){
        height: 100vh;
      } 
    `,
    row: css`
      ${tw`flex flex-wrap w-full max-w-row px-4 lg:px-2 mx-auto `};
         
    `,
    mainHeading: css`
      // font-family: Poppins-SemiBold;
      ${tw`font-sans text-xl`}     
      color: rgba(20,20,20,0.6);
      letter-spacing: -0.5px;
      span{
        // font-weight: 800;
        color: black;
      }
      @media (min-width: 992px){
        font-size: 2.5rem;
        line-height: 50px;
      }
  `,
    mainPara: css`
      ${tw`font-sans`}
      opacity: 0.4;
      color: #222222;
      letter-spacing: -0.05px;
      @media (min-width: 992px){
        line-height: 41.89px;
        font-size: 25.89px;
      }
  `,
    orderButton: css`
    ${tw`py-6 px-2 lg:py-6 mt-4 relative overflow-hidden lg:px-6 rounded-full bg-black text-white no-underline cursor-pointer w-3/5`};
      z-index: 0;
      transition: 0.25s ease-out;
      will-change: box-shadow transform;
      &:hover{
        transform: scale(1.01);
        transition: all 0.25s ease-in;
        box-shadow: 0 20px 30px -5px rgba(0,0,0,0.2);
      }
  `,
  bowl:css`
    ${tw`absolute h-24 lg:h-auto z-50  pin-b`}
    transform: translate3d(0, 50px, 0);
    left: 5%;
    @media (min-width: 992px){
      left: 60%;
      transform: translate3d(0,0,0);
    }
  `,
  leaf:css`
    ${tw`absolute z-30  pin-b`}
    left: 15%;
    bottom: 0;
    transform: translate3d(0, 50px, 0);
    height: 10rem;
    @media (min-width: 992px){
      height: auto;
      bottom: -50%;
      left: 60%;
      transform: translate3d(0,0,0);
    }
  `,
}

const SectionOneIndex = (props) => (

    <section className={styles.section}>
        <div className={styles.row}>
            <div className={css`${tw`w-full lg:w-1/2 mt-16`}`}>
                <div className={css`${tw`relative z-100`}`}>
                    <h1 className={styles.mainHeading}>How about <br /><span>fun, health, and taste,</span>  <br />all in a bowl?</h1>
                    <p className={styles.mainPara}>Indulge in America's new favorite food at a pokebowls near you.</p>
                    <div className={styles.orderButton}>
                      <a href="https://ordering.chownow.com/order/13891/locations"
                        target="_blank"
                        className={css`${tw`flex items-center justify-center text-white absolute h-full w-full pin-l pin-t z-10 cursor-pointer no-underline font-sans font-medium`}`}>
                        Order Delivery
                      </a>
                    </div>
                    <p className={css`${tw`text-sm text-grey mt-8 ml-2 font-sans`}`}>Available On</p>
                    <div className={css`${tw`flex flex-wrap items-center mt-1`}`}>
                        <img className={css`${tw`mx-2`}`} src={require('../../images/uberEatsLogo.svg')} alt="Uber Eats Logo" />
                        <img className={css`${tw`mx-2`}`} src={require('../../images/chowNowLogo.svg')} alt="Chow Now Logo" />
                    </div>
                </div>
            </div>
        </div>
        <img alt="Poke Bowl" src={require('../../images/bowlAndShadow.png')} className={styles.bowl} />
        <img alt="Poke Bowl" src={require('../../images/kale-leaf-cut.png')} className={styles.leaf} />
    </section>

);

export default SectionOneIndex;