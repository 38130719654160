import React from 'react'
import {css} from 'emotion'

/* global tw */
const styles = {
    section: css`
        ${tw`w-screen relative pt-12 pb-12`};
        // min-height: 100vh;
        height: auto;
        box-sizing: border-box;
    `,
    row: css`
        ${tw`w-full max-w-row flex flex-wrap items-center`};
    `,
    whatIsPoke: css`
        ${tw`font-sans lg:text-3xl font-black`};
        color: #000000;
        letter-spacing: -0.5px;
    `,
    descriptionBox: css`
        ${tw`mx-auto lg:-ml-6 w-full lg:w-1/3 py-8 lg:py-16 items-center justify-center px-4 lg:px-8 lg:text-right bg-white rounded-lg shadow-lg`};
        transform: translate3d(0,-50px, 0);
        @media (min-width: 992px){
            transform: translate3d(0,0,0);
        }
    `,
    description: css`
        font-family: Poppins;
        font-size: 0.809rem;
        opacity: 0.77;
        line-height: 1.618;
    `,
}
const SectionTwoIndex = (props) =>(
    <section className={styles.section}>

        <div className={css`${tw`h-full max-w-row mx-auto flex flex-wrap items-center`};`}>
            <div className={css`${tw`flex w-full lg:w-2/3 bg-grey-light h-full py-12 items-center justify-center`}`}>
                <img className={css`${tw``}; transform: translate3d(100px, 20px, 0);`} 
                     src={require('../../images/sushi-in-a-bowl.png')} 
                     alt="Poke - Sushi in a bowl"/>
            </div>
            <div className={styles.descriptionBox}>
                <h2 className={styles.whatIsPoke}>What is Poké?</h2>
                <p className={styles.description}>Poké (pronounced as Poh Kay) is a deconstructed sushi in a bowl. It’s a highly customizable, healthy fastfood served like a salad bowl</p>
            </div>
        </div>

    </section>
);

export default SectionTwoIndex;