import React from 'react'
import {css} from 'emotion'
import Swiper from 'react-id-swiper';
import '../../../node_modules/swiper/dist/css/swiper.css';


/* global tw */
const styles = {
    section: css`${tw`w-screen relative my-12 py-12`}`,
    row: css`${tw`flex flex-wrap mx-auto max-w-row`}`,
    heading: css`
        ${tw`font-sans font-medium ml-6 lg:ml-8 mt-12`};
        font-size: 1.62rem;
        @media (min-width: 992px){
            font-size: 2rem;
        }
        letter-spacing: -1.57px;
    `,
    carouselContainer: css`
        ${tw`flex relative z-back py-12 flex-wrap items-center w-full relative overflow-hidden bg-grey-light px-6 lg:px-8`};
        perspective: 3000px;
    `,
    review: css`
        ${tw`w-4/5 text-xl lg:w-1/2 mb-4 font-sans`};
        font-size: 1rem;
        letter-spacing: -0.5px;
        text-shadow: 0 10px 25px rgba(0,0,0,0.1);
        transform: translate3d(0,0,40px);
    `,
    reviewer: css`
        ${tw`font-sans text-xs text-grey-dark`};
    `,
}
class SectionFourIndex extends React.Component{
    
    constructor(props){
        super(props);
        this.swiper = null;
    }

    render(){
        const sliderSettings = {
            rebuildOnUpdate	: true,
            shouldSwiperUpdate: true,
            slidesPerView: 1,
            // width: '100%',
            autoplay: {
                delay: 6000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true
            },
            loop: true,
            speed: 1000,
            initialSlide: 0,
            renderCustom: function (swiper, current, total) {
                return (
                    <div className={css`${tw`h-4 w-4 bg-pink rounded-full mx-4 inline-flex`}`}>
                        {current}
                    </div>
                );
            }
            
        }
        return(
            <section className={styles.section}>

                <div className={styles.row}>
                    <h2 className={css`${tw`lg:ml-6 text-3xl font-black`}; letter-spacing: -0.5px;`}>Our Customers on Yelp</h2>
                </div>
                <div className={styles.row}>

                    <div className={styles.carouselContainer}>
                        <div className={css`${tw`relative z-10`}`}>
                            <Swiper {...sliderSettings} ref={node => {if (node) this.swiper = node.swiper} }>

                                <div>
                                    <p className={styles.review}>
                                        “I'll definitely be back when I get the cravings for some raw fish and rice ( which won't be long) MN”
                                    </p>
                                    <p className={styles.reviewer}>Michael N. <br/> Daly City</p>
                                </div>
                                <div>
                                    <p className={styles.review}>
                                       The ponzu and spicy mayo sauce goes very well together and the portion size is decent.
                                    </p>
                                    <p className={styles.reviewer}>Zia D. <br/>South San Francisco</p>
                                </div>
                                <div>
                                    <p className={styles.review}>
                                        “I chose to mix the mild poke sauce and the miso sauce, and topped the bowl off with masago eggs, green onions, and sesame seeds.” 
                                    </p>
                                    <p className={styles.reviewer}>Susy C, San Francisco</p>
                                </div>
                            </Swiper>
                            <div className={css`${tw`flex w-full items-center mt-8`}`}>
                                {/* <div className={'swiper-pagination' + css`${tw`h-4 w-4 rounded-full bg-pink mr-4 `}`}/>
                                <div className={'swiper-pagination' + css`${tw`h-4 w-4 rounded-full bg-pink mr-4 `}`}/>
                                <div className={'swiper-pagination' + css`${tw`h-4 w-4 rounded-full bg-pink mr-4 `}`}/> */}
                                {/* <div className={swiper-}></div> */}
                            </div>
                        </div>
                        <img className={css`${tw`absolute z-0 h-24`}; opacity: 0.2;`} src={require('../../images/hashtagGraphic.svg')} alt="Hashtag Graphic"/>
                    </div>

                </div>
            </section>
        );
    }
}

export default SectionFourIndex;