import React, { useState, useCallback } from 'react'
import {css} from 'emotion'
import Swiper from 'react-id-swiper';
import '../../../node_modules/swiper/dist/css/swiper.css';
import { useTransition, animated } from 'react-spring'

import BaseImg from '../../images/steps/base.png';
import AppetizerImg from '../../images/steps/appetizer.png';
import PokeImg from '../../images/steps/poke.png';
import SaucesImg from '../../images/steps/sauces.png';
import SidesImg from '../../images/steps/sides.png';

/* eslint-disable */
/* global tw */
const styles ={
    section: css`
        ${tw`w-screen relative lg:py-3 mb-12 z-0`};
        height: 25rem;
        @media (min-width: 992px){
            height: 100vh;
        }
    `,
    number: css`
        ${tw`inline-flex items-center justify-center text-center my-1 mr-8 text-white rounded-full border-2 border-solid border-white font-sans font-bold p-4 cursor-pointer hover:bg-white hover:text-black`}; 
        height: 40px; 
        width: 40px;
        transition: all 0.2s ease-in-out;
    `,
    howToStepsWrapper: css`
        ${tw`relative z-0 flex items-center justify-center max-w-row xl:w-full flex flex-wrap bg-black h-full lg:h-3/4 lg:mx-auto mr-0 overflow-hidden`};
        background-repeat: no-repeat;
        background-size: cover;
        @media (min-width: 992px){
            border-radius: 16.18px 16.18px 0 0;
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 110%;
            width: 100%;
            z-index: 1;
            background-image: linear-gradient(to top, rgba(0,0,0,1) 15%, rgba(0,0,0,0.1)100% );
        }
    `,
}

class SectionThreeIndex extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            action: "Pick your Base",
            description: "White / Brown Rice, Salad, Chips or Kelp Noodle",
            image: BaseImg,
        }
        this.swiper = null;
        this.howToOrder = this.howToOrder.bind(this);
    }

    componentDidMount(){
        console.log(this.swiper);
    }

    howToOrder(step){
        if(step === 1){
            this.setState({
                action: "Pick your Base",
                description: "White / Brown Rice, Salad, Chips or Kelp Noodle",
                image: BaseImg,
                step: 1,
            })
        }
        else if(step === 2){
            this.setState({
                action: "Choose your Side",
                description: "Cucumber, Sweet Corn, Carrot, White Onion, Jalapeno",
                image: SidesImg,
                step: 2,
            })
        }
        else if(step === 3){
            this.setState({
                action: "Pick your Poké",
                description: "Crab, Spicy crab, Salmon, Tuna, Scallop , Shring, Tofu, Hanachi, Unagi etc.",
                image: PokeImg,
                step: 3,
            })
        }
        else if(step === 4){
            this.setState({
                action: "Select your Sauce",
                description: "Spicy Mayo, Japanese Kimchee, Miso, Unagi, Honey Avocado etc.",
                image: SaucesImg,
                step: 4,
            })
        }
        else if(step === 5){
            this.setState({
                action: "Top it off",
                description: "Avocado, Tomato, Pineapple, Seaweed Slad, Edamame, Jelly Fish, Ginger etc.",
                image: SidesImg,
                step: 5,
            })
        }
        else if(step === 6){
            this.setState({
                action: "Add any appetizer",
                description: "Miso Soup, Seaweed Salad, Kimchee, Kelly Fish, Poke Ball (limited)",
                image: AppetizerImg,
                step: 6,
            })
        }
        else{
            this.setState({
                action: "Something's weird",
                description: "Something is not right on the site.",
                image: SaucesImg,
                step: -1,
            })
        }
    }

    render(){
        const sliderSettings ={
            slidesPerView: 'auto',
            width: 100,
            spaceBetween: 10,
            initialSlide: 0,
        }
        return(
            <section className={styles.section}>
                <div className={css`${tw`max-w-row mx-auto pl-6 flex flex-wrap`}`}>
                    <h2 className={css`${tw`text-3xl font-black`}; letter-spacing: -0.5px;`}>How do you Order Poké?</h2>
                </div>
                
                <div style={{backgroundImage: `url(${this.state.image})`}} className={styles.howToStepsWrapper}>
                        
                    <div className={css`${tw`absolute z-10`}; bottom: 15%; left: 5%;`}>


                        <h3 className={css`${tw`text-white text-xl lg:text-4xl mb-2`};`}>
                            {this.state.action}
                        </h3>
                        <p className={css`${tw`text-white opacity-50 font-sans`}`}>
                            {this.state.description}
                        </p>
                        
                        <div className={css`${tw`flex flex-wrap mx-auto w-screen lg:max-w-row flex items-center my-6`}`}>
                            <div onClick={() => this.howToOrder(1)}
                                className={styles.number}>
                                1
                            </div>
                                <div onClick={() => this.howToOrder(2)}
                                    className={styles.number}>
                                    2
                            </div>
                                <div onClick={() => this.howToOrder(3)}
                                    className={styles.number}>
                                    3
                            </div>
                                <div onClick={() => this.howToOrder(4)}
                                    className={styles.number}>
                                    4
                            </div>
                                <div onClick={() => this.howToOrder(5)}
                                    className={styles.number}>
                                    5
                            </div>
                                <div onClick={() => this.howToOrder(6)}
                                    className={styles.number}>
                                    6
                            </div>

                        </div>
                    </div>
        
                </div>
                <div className={css`${tw`hidden lg:flex items-end pb-12 absolute z-back h-auto min-h-4/5 bg-black w-full`}; bottom: 0%; left: 2.5%;`}>                        
                </div>
            </section>
        );
    }
} 

export default SectionThreeIndex;